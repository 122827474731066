import { render, staticRenderFns } from "./DataLandList.vue?vue&type=template&id=c2332e98&scoped=true&"
import script from "./DataLandList.vue?vue&type=script&lang=js&"
export * from "./DataLandList.vue?vue&type=script&lang=js&"
import style0 from "./DataLandList.vue?vue&type=style&index=0&id=c2332e98&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2332e98",
  null
  
)

export default component.exports